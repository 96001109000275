window.ambiente = window.ambiente || 'prod';
//logica per attivare i template
const templateToActivate = document.querySelectorAll('.par-template-to-activate');
templateToActivate.forEach(function(template) {
    mostraTemplate(template);
});
function mostraTemplate(template) {
    const templateContent = template.content.cloneNode(true);
    document.body.appendChild(templateContent);
}

// controllo Leggi Tutto espandibile:
const rMoreDiv = document.querySelector('.read-more-target');
if (rMoreDiv) {
	let rMoreCls = rMoreDiv.firstElementChild.classList;
	rMoreCls.remove('par-mBottom4x');
	if (rMoreDiv.scrollHeight > rMoreDiv.clientHeight) {
		rMoreDiv.classList.add('read-more-target-expanding');
	}
	rMoreCls.add('par-mBottom4x');
	let closerMore = document.querySelector('.read-more-state');
	rMoreDiv.addEventListener('click',() => {
		if (closerMore.checked) {
			window.scrollTo({
				top: 100,
				left: 0,
				behavior: 'smooth'
			});
		}
	});
}


/* gestione colore skin */
function deltaE(rgbA, rgbB) {
	let labA = rgb2lab(rgbA);
	let labB = rgb2lab(rgbB);
	let deltaL = labA[0] - labB[0];
	let deltaA = labA[1] - labB[1];
	let deltaB = labA[2] - labB[2];
	let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
	let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
	let deltaC = c1 - c2;
	let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
	deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
	let sc = 1.0 + 0.045 * c1;
	let sh = 1.0 + 0.015 * c1;
	let deltaLKlsl = deltaL / (1.0);
	let deltaCkcsc = deltaC / (sc);
	let deltaHkhsh = deltaH / (sh);
	let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
	return i < 0 ? 0 : Math.sqrt(i);
}

function rgb2lab(rgb) {
	let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
	r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
	g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
	b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
	x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
	y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
	z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
	x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
	y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
	z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;
	return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
}

window.addEventListener('skinData', (e) => {
    if (device == 'desktop' && e.detail.url && e.detail.color.toLowerCase() == '#fdfaf9') handleBgSkin(e.detail.url);
});

function handleBgSkin(url){
	let startTime = performance.now();
	let page = document.querySelector('.mh2021Page');
	var canvas = document.createElement("canvas");
	var pic = new Image();
	pic.crossOrigin = "Anonymous";
	pic.onload = function () {

        const populatePoint = (pointName, p1, p2, p3, p4) => {
            const point = c.getImageData(p1, p2, p3, p4).data;
            pointName.data = point.slice(0,3);
            pointName.hex = point[0] + "," + point[1] + "," + point[2];
        };

        const checkBestColor = (color) => (color != '255,255,255')? color : bestColor;

        const checkBestValues = (distance, color) => {
            if(distance < bestDistance) {
                bestDistance = distance;
                bestColor = checkBestColor(color);
            }
        }

		canvas.width = pic.width;
		canvas.height = pic.height;
		var ctx = canvas.getContext("2d");
		ctx.drawImage(pic,0,0);

		var c = canvas.getContext('2d');

		const pBottomSx = {};
		const pBottomDx = {};
		const pTopSx = {};
		const pTopDx = {};

		populatePoint(pBottomSx, 0, pic.height - 1, 1, 1);
		populatePoint(pBottomDx, pic.width - 1, pic.height - 1, 1, 1);
		populatePoint(pTopSx, 0, 0, 1, 1);
		populatePoint(pTopDx, pic.width - 1, 0, 1, 1);

		let bestDistance = deltaE(pTopSx.data, pTopDx.data);
		let distanceBottom = deltaE(pBottomSx.data, pBottomDx.data);
		let distanceSideSx = deltaE(pBottomSx.data, pTopSx.data);
		let distanceSideDx = deltaE(pBottomDx.data, pTopDx.data);
		console.log('[skincolor] distanza top', bestDistance, '\n'+
                    '[skincolor] distanza bottom', distanceBottom, '\n'+
                    '[skincolor] distanza lato sinistro', distanceSideSx, '\n'+
                    '[skincolor] distanza lato destro', distanceSideDx);

		let bestColor = '255,255,255';
        bestColor = checkBestColor(pTopSx.hex);

        checkBestValues(distanceBottom, pBottomSx.hex);
        checkBestValues(distanceSideSx, pBottomSx.hex);
        checkBestValues(distanceSideDx, pBottomDx.hex);
        
		page.style.backgroundColor = `rgb(${bestColor})`;
        
		console.log('[skincolor] durata', Math.round(performance.now() - startTime)+'ms');
	}

	pic.src = url;
}


//startPushNoConsent
(function () {
    /*let iframeVideo = document.querySelector('.tw-container-video');
    if(iframeVideo != null) {
        console.log('[pushNoConsent] bloccata per presenza video in pagina');
        return false;
    }*/
  
    let trackAnalytics = false;
    let ptpWebsite = 'pmpv';

    function startPushNoConsent() {
        window.initStartPushNoConsent = 1;
        console.log('[pushNoConsent] track init');
        if (typeof gtag === "function" && trackAnalytics == true) {
            gtag("event", 'pushNoConsentV1', {
              event_category: 'pushNoConsentV1',
              event_action: 'init',
              event_label: document.location.href,
              non_interaction: true
            });
        }

        window.GlobalPusTimerStart = new Date().getTime();
        
        var ptpAdserver = 'freewheel';


        let obj = {
            website: ptpWebsite,
            type: "flyfirst",
            flyMode: "fade",
            closeFlyingAfter: 7000,
            adServer: ptpAdserver,
            eventListener: (eventName, eventData) => {
            	const eventBlocking = [
					"adblocker_detected",
					"preroll_not_served",
					"autoplay_denied",
					"triggered_fallback",
					"preroll_error"
				];
                if(eventName === 'preroll_started'){
                    console.log('[pushNoConsent]', 'track preroll started');
                     if (typeof gtag === "function" && trackAnalytics == true) {
                        gtag("event", 'pushNoConsentV1', {
                            event_category: 'pushNoConsentV1',
                            event_action: 'preroll_started',
                            event_label: document.location.href,
                            non_interaction: true
                        });
                    }
                }
                // [ NPA ] event listeners per interstital ADK (adv fill se non c'è preroll)
				// if (eventBlocking.includes(eventName)) {
				// 	if (!adkPageSlots.displayed.adk_interstitial) {
				// 		console.debug('[ NPA ] init interstitial ADK causa preroll non erogato');
				// 		window.adkaoraCanShowInterstitial = true;
				// 	}
				// }
            }
        }

        obj['targetElementId'] = 'flyfirst-mobile-placement';
        if (window.innerWidth >= 1000) obj['targetElementId'] = 'flyfirst-placement';

        (function (a, l, t, e, r) {
            a[e] = a[e] || function (s) {
                (a[e].run = a[e].run || []).push(s)
            };
            let g, z = l.getElementsByTagName(t)[0];
            if (l.getElementById(e)) {
                return;
            }
            g = l.createElement(t);
            g.id = e;
            g.src = r;
            g.async = 1;
            z.parentNode.insertBefore(g, z);
        }(window, document, 'script', 'AVPushLoader', "https://ptp.stbm.it/pushdown/loader/av/pushdown-loader.js"));

        console.log('[pushNoConsent]', obj);

        AVPushLoader(obj);
    }

    // function startInterstitialNoConsent(){
	// 	// [ NPA ] event listeners per interstital ADK (adv fill se non c'è preroll)
	// 	const eventBlocking = [
	// 		"adblocker_detected",
	// 		"preroll_not_served",
	// 		"autoplay_denied",
	// 		"triggered_fallback",
	// 		"preroll_error"
	// 	];
	// 	eventBlocking.forEach(function(e) {
	// 		window.addEventListener(e, function() {
	// 			if (!adkPageSlots.displayed.adk_interstitial) {
    //                 console.debug('[ NPA ] init interstitial ADK causa preroll non erogato');
    //                 window.adkaoraCanShowInterstitial = true;
    //             }
	// 		});
	// 	})
	// }

    const ADVNoConsentFlow = () => {
        // let NPAtarget = window.innerWidth < 1000 ? 'adv-gpt-box-mobile-container1' : 'adv-gpt-box-container1';
        // document.getElementById(NPAtarget).parentNode.parentNode.setAttribute('id','box300NPA');

        setTimeout(function() {
            mmLoader({
                src: "https://cdn.adkaora.space/mondadori/generic/prod/adk-init.js",
                async: true
            }, "ADVNoConsentFlow")
            // .then(element => {
            //     document.addEventListener("PWTBidComplete", function(e) {
            //         console.debug('PWTBid completed.')
            //         // check slot vuoti:
            //         let emptyNPASlots = {};
            //         if(ifrmAdvMh.offsetHeight === 0){
            //             Object.assign(emptyNPASlots, { 'masthead': divslotnameStrip });
            //         } else {
            //             AmeMh.strip_animation();
            //         }
            //         if(ifrmAdvBox.offsetHeight === 0){
            //             Object.assign(emptyNPASlots, { 'box300': divslotnameBox });
            //         }
            //         document.dispatchEvent(new CustomEvent("EmptyNPASlot", {
            //             detail:  emptyNPASlots
            //         }));

            //     });
            // }, "ADVNoConsentFlow")
            // .then( element => mmLoader({
            //     src: "https://adv.mediamond.it/librerie/js-no-consent/ame/adv_no_consent.js",
            //     async: true
            // }, "ADVNoConsentFlow"))
            .then(element => {
                setTimeout(function() {startPushNoConsent() },15000);
                // startInterstitialNoConsent();
            }, "ADVNoConsentFlow");
        },500);

    }

    postConsentRejected(ADVNoConsentFlow);

    // debug only:
    document.addEventListener("EmptyNPASlot", function(e) {
        console.debug('[NPA] EmptyNPASlot event:', e.detail);
    });
    
})();

(function () {
    // Google AFS 
    let pageOptions;
    
    postTcfReady(()=>{
		mmLoader({
				src: "https://www.google.com/adsense/search/ads.js",
				async: true
			}, "adsChain");

		(function(g,o){g[o]=g[o]||function(){(g[o]['q']=g[o]['q']||[]).push(
		arguments)},g[o]['t']=1*new Date})(window,'_googCsa');

		pageOptions = {
			"pubId": "partner-pub-7835681492882497", // Assicurati che l'ID cliente sia corretto.
			"styleId": "2459062657",
			"resultsPageBaseUrl": document.location.origin + "/cerca", // Inserisci l'URL di base per la pagina dei risultati
			"resultsPageQueryParam": "search" // (Valore predefinito: "q") Corrisponde al parametro che identifica la query nella pagina di ricerca
		};

		if(document.location.href.includes("/cerca/?") || document.location.href.includes("/cerca?")){

			let params = new URL(document.location).searchParams;
			let queryGet = (params.get("search") !== null) ? params.get("search") : "";
			const containerName = "adscorrelaticontainer";

			var adblock1 = {
                "container": containerName,
				"adLoadedCallback" : function(containerName, adsLoaded,
					isExperimentVariant, callbackOptions) {
                        const selectorContainer = document.querySelector("#" + containerName);
						selectorContainer.classList.add("adv-checked", "sal-pBottom5x");
						if (!adsLoaded) {
							console.log ("[ads ricerca] - no ads");
							selectorContainer.classList.add("no-adv");
							selectorContainer.classList.remove("sal-pBottom5x");
						}
				  }
            };

			pageOptions.query = queryGet; // Assicurati che la query inserita qui sia corretta.
			pageOptions.adsafe = "high";
			_googCsa('ads', pageOptions, adblock1);

		} else {
            const relatedLeaf = document.querySelector("#relatedSearchesLeaf");

            if(relatedLeaf !== null){
                const rsblockArticle = {
                    "container": "relatedSearchesLeaf",
                    "relatedSearches": 6
                };

                pageOptions.relatedSearchTargeting = "content";
                _googCsa('relatedsearch', pageOptions, rsblockArticle);
            }
        }
	});

})();